import * as React from "react";

import loadable from "@loadable/component";
const Carousel = loadable(() => import("tiny-slider-react"));

const Testimonials = () => {
  return (
    <section id="reviews" className="px-2 py-8 text-gray-300 bg-gray-800">
      <Carousel
        settings={{
          swipeAngle: false,
          items: 1,
          mouseDrag: true,
          controls: false,
          nav: true,
          navPosition: "bottom",
          autoplay: true,
          autoplayButtonOutput: false,
          rewind: true,
        }}
      >
        <div className="slide">
          <div className="max-w-2xl mx-auto">
            <blockquote className="mt-4">
              <div className="text-xl italic font-display md:text-2xl">
                <em className="not-italic">The Berserkers</em> is original and
                surprising in all the right ways . It’s extremely well
                written—not only is the prose clean and clear, there’s a real
                spark of life in the language that makes the book hum. The
                author throws off exquisite sentences like he's broadcasting
                birdseed.
              </div>
              <footer className="pl-4 mt-4 border-l lg:pl-8">
                <b className="text-white">Jack Livings</b>, author of{" "}
                <i>The Blizzard Party</i> and winner of the 2015 PEN/Robert W.
                Bingham Prize and the Rome Prize for literature.
              </footer>
            </blockquote>
          </div>
        </div>
        <div className="slide">
          <div className="max-w-2xl mx-auto">
            <blockquote className="mt-4">
              <div className="text-xl italic font-display md:text-2xl">
                This is an engaging, tightly written experimental novel, full of
                evocative storytelling and full of narrative drive. I
                particularly enjoyed the narrator’s confusion about being chosen
                to investigate, his protestations and the rather opaque world of
                Scandinavian policing. It adds a rather wonderfully dark, almost
                comedic effect and lightens the drama in just the right way.
              </div>
              <footer className="pl-4 mt-4 border-l lg:pl-8">
                <b className="text-white">Wyl Menmuir</b>, author of{" "}
                <i>The Many</i>, longlisted for the Man Booker Prize 2016
              </footer>
            </blockquote>
          </div>
        </div>
      </Carousel>
    </section>
  );
};

export default Testimonials;
