import PropTypes from "prop-types";
import React from "react";

const Button = ({ children, href, ...params }) => {
  const className =
    "inline-block px-6 py-2 text-white bg-indigo-600 outline-none hover:bg-indigo-800";

  if (href) {
    return (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        className={className}
      >
        {children}
      </a>
    );
  } else {
    return (
      <button className={className} {...params}>
        {children}
      </button>
    );
  }
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  href: PropTypes.string,
};

Button.defaultProps = {
  href: null,
};

export default Button;
