import React from "react";
import { Link } from "gatsby";

const Footer = () => (
  <footer className="p-4 mt-8 text-sm text-center text-gray-600 lg:mt-12">
    ©2021 Vic Peterson |{" "}
    <Link className="underline" to="/privacy/">
      Privacy
    </Link>
  </footer>
);

export default Footer;
