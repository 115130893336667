import classNames from "classnames";
import addToMailchimp from "gatsby-plugin-mailchimp";
import React, { useState } from "react";
import Button from "./button";

const MailchimpForm = () => {
  const [email, setEmail] = useState();
  const [message, setMessage] = useState();
  const [disabled, setDisabled] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setDisabled(true);
    setMessage("Sending...");
    const response = await addToMailchimp(email);
    if (response.result === "error") {
      if (response.msg.toLowerCase().includes("already subscribed")) {
        setMessage("You're already on to the list!");
      } else {
        setMessage("Some error occured while subscribing you to the list.");
      }
      setDisabled(false);
    } else {
      setMessage(
        "Thanks! Please check your e-mail and click the confirmation link."
      );
    }
  };

  return (
    <>
      <form className="flex justify-center mt-4" onSubmit={handleSubmit}>
        <input
          aria-label="Email address"
          className=""
          onChange={(event) => setEmail(event.target.value)}
          placeholder="Enter your email"
          required
          type="email"
        />
        <Button disabled={disabled}>Sign up</Button>
      </form>
      <div className={classNames("w-full text-sm", { hidden: !message })}>
        {message}
      </div>
    </>
  );
};

export default MailchimpForm;
