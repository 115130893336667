import React, { useState } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Helmet } from "react-helmet";
import "../style.css";

import MailchimpForm from "../components/mailchimpForm";
import Button from "../components/button";
import Footer from "../components/footer";
import Header from "../components/header";

import Testimonials from "../components/testimonials";

// markup
const IndexPage = (props) => {
  const [formIsOpen, setFormIsOpen] = useState(false);

  const toggleForm = () => {
    setFormIsOpen(!formIsOpen);
    document.addEventListener("keydown", handleEsc);
  };

  const handleEsc = async (event) => {
    console.log(event.keyCode);
    if (event.keyCode === 27) {
      setFormIsOpen(false);
      document.removeEventListener("keydown", handleEsc);
    }
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>The Berserkers - A Novel by Vic Peterson</title>
        <link rel="canonical" href="https://www.vicpeterson.com" />
      </Helmet>
      <Header isOpen={formIsOpen} buttonAction={toggleForm} />
      <main className="text-gray-700">
        <section>
          <div className="relative">
            <StaticImage
              className="w-full h-screen lg:max-h-[800px] min-h-[600px] filter-brightness-70 block"
              src="../images/landscape.jpg"
              alt="a dreary nordic landscape with mist"
              placeholder="blurred"
              width={960}
              height={720}
            />
            <div className="absolute inset-0 flex flex-col justify-center">
              <h1 className="text-center text-white">
                <div className="text-5xl sm:text-7xl md:text-8xl font-display text-shadow-md">
                  The Berserkers
                </div>
                <div className="mt-4 text-xl tracking-wider uppercase">
                  A Novel by Vic Peterson
                </div>
                <div class="text-sm mt-2">
                  Hawkwood Books 2022 / Recital 2023
                </div>
              </h1>
            </div>
            <div className="absolute bottom-0 w-full text-center text-white">
              <div>
                <Button
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.amazon.com.au/Berserkers-Vic-Peterson/dp/1838024778"
                >
                  <b>Paperback</b> (USA)
                </Button>{" "}
                <Button
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.waterstones.com/book/the-berserkers/vic-peterson/9781838024772"
                >
                  <b>Paperback</b> (UK)
                </Button>
              </div>
              <div class="mt-2 mb-4">
                <Button
                  target="_blank"
                  rel="noreferrer"
                  href="https://open.spotify.com/show/5QRqKqUJvgixDZY4blYkAF"
                >
                  <b>Audiobook</b>
                </Button>{" "}
                <Button
                  target="_blank"
                  rel="noreferrer"
                  href="https://recitalpublishing.com/the-berserkers/"
                >
                  <b>E-Book</b>
                </Button>
              </div>
              <a href="#blurb" className="inline-block">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  className="w-16 h-16 opacity-75 hover:opacity-100"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </a>
            </div>
          </div>
        </section>
        <section id="blurb" className="px-2 py-8 lg:py-12">
          <div className="flex flex-col items-center justify-center gap-6 mx-auto md:flex-row lg:text-lg">
            <div className="flex-shrink-0">
              <StaticImage
                src="../images/berserkers-cover.jpg"
                alt="The Berserkers Book Cover"
                width={300}
              />
            </div>
            <div className="max-w-xl">
              <p>
                <b>
                  When a beautiful woman is found stabbed and frozen in the ice
                </b>{" "}
                of Lake Munch, dressed only in the costume wings and tight
                corset of a Norse Valkyrie, the small and underfunded police
                department of Fulaflugahål is under pressure to find her killer.
                Grammaticus Kolbitter, precinct records clerk by day and
                electronic keyboardist in the Viking heavy-metal band,{" "}
                <i>The Berserkers</i>, by night, is unwillingly pulled into the
                investigation. What does a records clerk know about solving
                crime, much less, bringing murderers to justice?
              </p>
              <p className="mt-2">
                As Grammaticus embarks on the adventure of a lifetime, picking
                up magical friends and spiritual guides along the way, he soon
                learns he knows more than he thought. A rollicking ride filled
                with dark humor, wit, and plenty of Viking heavy-metal riffs,
                this Nordic mystery will keep you laughing, and thinking, all
                the way to the final mosh pit.
              </p>
            </div>
          </div>
          <hr class="max-w-lg mx-auto my-8" />
          <div class="max-w-2xl mx-auto text-center mt-8">
            <p class="font-bold">
              Listen to a chapter reading and author interview on{" "}
              <a
                class="hover:underline"
                target="_blank"
                rel="noreferrer"
                href="https://thestrangerecital.com/episodes-2/"
              >
                Strange Recital podcast
              </a>
              :
            </p>
            <iframe
              className="mt-2"
              title="Libsyn Player"
              src="//html5-player.libsyn.com/embed/episode/id/25288935/height/90/theme/custom/thumbnail/yes/direction/forward/render-playlist/no/custom-color/000000/"
              height="90"
              width="100%"
              allowfullscreen
              webkitallowfullscreen
              mozallowfullscreen
              oallowfullscreen
              msallowfullscreen
            ></iframe>
          </div>
        </section>

        <section className="px-2 py-12 bg-gray-100">
          <div className="max-w-2xl mx-auto">
            <p className="text-center">
              <b>
                Enter your email to read the first chapter of{" "}
                <i>The Berserkers</i>
              </b>
            </p>
            <MailchimpForm />
          </div>
        </section>

        <Testimonials />

        <section id="bio" className="px-2 py-8">
          <div className="max-w-screen-md mx-auto">
            <div className="items-center justify-center space-y-4 md:flex md:space-x-8">
              <div>
                <StaticImage
                  src="../images/headshot.jpg"
                  alt="Vic Peterson headshot"
                  placeholder="blurred"
                  layout="fixed"
                  className="mx-auto rounded-full"
                  width={240}
                  height={240}
                />
              </div>
              <div className="">
                <p>
                  <b>Vic Peterson</b> was educated at Kenyon College, the
                  University of Texas (Dallas), and the University of Chicago
                  and has completed novel-writing courses at the Faber Academy
                  and Curtis Brown Creative. He worked as a business executive
                  and now divides his time between Lawrence, Kansas, and
                  Northport, Michigan.
                </p>
                <div className="mt-2">
                  <Button onClick={toggleForm}>Contact</Button>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="links" className="px-2 mt-8">
          <div className="max-w-screen-md grid-cols-1 mx-auto md:grid md:grid-cols-2 gap-x-6 gap-y-4">
            <div>
              <h3 className="text-xl font-display">Literary Friends</h3>
              <ul className="pl-6 list-disc">
                <li>
                  <a
                    className="hover:underline"
                    href="http://tashahaas.com/about/"
                  >
                    Tasha Haas - Fiction Writer
                  </a>
                </li>
                <li>
                  <a
                    className="hover:underline"
                    href="https://jacklivings.com/"
                  >
                    Jack Livings - Editor/Award-Winning Novelist
                  </a>
                </li>
                <li>
                  <a
                    className="hover:underline"
                    href="https://www.wylmenmuir.co.uk/"
                  >
                    Wyl Menmuir - Booker Nominated Novelist
                  </a>
                </li>
                <li>
                  <a
                    className="hover:underline"
                    href="https://karenvaughn.info/about/"
                  >
                    Karen M. Vaughan - Fiction Writer
                  </a>
                </li>
                <li>
                  <a
                    className="hover:underline"
                    href="https://www.coneflower.org/"
                  >
                    Coneflower Consulting{" "}
                  </a>
                </li>
              </ul>
              <h3 className="mt-4 text-xl font-display">Publishers</h3>
              <ul className="pl-6 list-disc">
                <li>
                  <a
                    className="hover:underline"
                    href="http://www.hawkwoodbooks.co.uk/"
                  >
                    Hawkwood Books
                  </a>
                </li>
                <li>
                  <a
                    className="hover:underline"
                    href="https://recitalpublishing.com/"
                  >
                    Recital Publishing
                  </a>
                </li>
              </ul>
            </div>
            <div>
              <h3 className="text-xl font-display">Reviews</h3>
              <ul className="pl-6 list-disc">
                <li>
                  <a
                    className="hover:underline"
                    href="https://dactylreview.com/2022/12/29/the-berserkers-by-vic-peterson-2/"
                  >
                    Brent Robinson, <i>Dactyl Review</i>
                  </a>
                </li>
              </ul>

              <h3 className="text-xl mt-4 font-display">Musical Bonus</h3>
              <ul className="pl-6 list-disc">
                <li>
                  <a
                    className="hover:underline"
                    href="https://soundcloud.com/user-755151639"
                  >
                    Small Beer - Song Based on Lyrics from The Berserkers
                  </a>
                </li>
              </ul>

              <h3 className="mt-4 text-xl font-display">Shout Out</h3>
              <ul className="pl-6 list-disc">
                <li>
                  <a
                    className="hover:underline"
                    href="https://www.facebook.com/leaveseyesofficial"
                  >
                    Leaves's Eyes - Viking Metal Band, model for The Berserkers
                  </a>
                </li>
                <li>
                  <a
                    className="hover:underline"
                    href="https://fitzgeraldsrealm.com/"
                  >
                    Ron Fitzgerald - Gothic Illusionist
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
};

export default IndexPage;
