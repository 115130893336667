import React from "react";
import Button from "./button";

import classNames from "classnames";

const Header = (props) => {
  const { isOpen } = props;

  const handleClick = async (event) => {
    props.buttonAction();
  };

  return (
    <section
      id="contact"
      className={classNames(
        "fixed top-0 z-50 w-full shadow-lg transform transition overflow-visible",
        {
          "-translate-y-full": !isOpen,
          "translate-y-0": isOpen,
        }
      )}
    >
      <div className={classNames("absolute z-50 shadow-md top-full right-5")}>
        <Button onClick={handleClick}>Contact</Button>
      </div>
      <div class="bg-gray-200 p-4 md:px-0 md:py-8">
        <div className="max-w-2xl mx-auto">
          <h2 className="text-3xl font-display">Contact Vic</h2>
          <form
            method="POST"
            name="VP.com Contact Form"
            data-netlify="true"
            className="mt-4 space-y-4"
          >
            <input type="hidden" name="form-name" value="VP.com Contact Form" />
            <div className="grid-cols-2 gap-4 md:grid">
              <div>
                <label className="block text-gray-600" htmlFor="user-name">
                  Your Name
                </label>
                <input
                  className="w-full mt-2"
                  name="user-name"
                  type="text"
                  required
                />
              </div>
              <div>
                <label className="block text-gray-600" htmlFor="user-email">
                  Your Email
                </label>
                <input
                  className="w-full mt-2"
                  name="user-email"
                  type="email"
                  required
                />
              </div>
            </div>
            <div>
              <label className="block text-gray-600" htmlFor="user-message">
                Your Message
              </label>
              <textarea
                className="w-full mt-2"
                name="user-message"
                rows="5"
                maxlength="300"
                required
              />
            </div>
            <Button type="submit">Submit</Button>
          </form>
        </div>
      </div>
      <div className="absolute top-0 right-0 p-4">
        <button onClick={handleClick}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-10 h-10"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>
    </section>
  );
};

export default Header;
